/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-this-alias */

// https://gist.github.com/fr-ser/ded7690b245223094cd876069456ed6c
export function debounce<F extends (this: any, ...a: any[]) => any>(func: F, wait: number): F {
  let timeoutID: number;

  // conversion through any necessary as it wont satisfy criteria otherwise
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return <any>function (this: any, ...args: any[]) {
    window.clearTimeout(timeoutID);
    const context = this;

    timeoutID = window.setTimeout(function () {
      func.apply(context, args);
    }, wait);
  };
}

export function debounceCallFirst<F extends (this: any, ...a: any[]) => any>(func: F, wait: number): F {
  let lastCallMs: number = 0;

  // conversion through any necessary as it wont satisfy criteria otherwise
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return <any>function (this: any, ...args: any[]) {
    const context = this;
    const nowMs = (new Date()).getTime();

    if (nowMs - lastCallMs > wait) {
      lastCallMs = nowMs;
      func.apply(context, args);
    }
  };
}
