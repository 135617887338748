import type { KeyboardEvent, KeyboardEventHandler, MouseEvent, MouseEventHandler, ReactNode } from "react";
import Skeleton from "react-loading-skeleton";
import * as E from "fp-ts/lib/Either";
import { constVoid, pipe } from "fp-ts/lib/function";
import { Key } from "ts-key-enum";

import { O } from "@scripts/fp-ts";
import type { KlassList, KlassProp } from "@scripts/react/util/classnames";
import { klass, klassProp, klassPropO } from "@scripts/react/util/classnames";

import { card as c } from "@styles/components/_card";

export type CardProps = {
  klasses: KlassProp;
  children: ReactNode;
  onClick?: MouseEventHandler;
  onKeyPress?: KeyboardEventHandler;
};

export const handleCardClickWithInternalLinksOrButtons = (onCardClick: MouseEventHandler) =>
  (e: MouseEvent) => pipe(
    O.fromNullable(e.target),
    O.chain((target) => target instanceof HTMLElement
      ? O.fromNullable(target.closest("a") || target.closest("button"))
      : O.none
    ),
    O.fold(
      () => { onCardClick(e); },
      constVoid,
    )
  );

export const Card = (props: CardProps) =>
  <div
    onClick={props.onClick}
    onKeyPress={props.onKeyPress}
    {...klassProp([c[".card"], props.onClick != null ? "card-hover card-link" : ""])(props.klasses)}
  >
    {props.children}
  </div>;

type ExternalLink = { dataHref: string, dataTarget: "_blank" | "_self" };
export type CardNavigationType = E.Either<{ dispatch: () => void }, ExternalLink>;

type CardNavProps = Omit<CardProps, "onClick" | "onKeyPress"> & {
  analyticsAction: () => void;
  navigation: CardNavigationType;
};

export const CardNavigation = (props: CardNavProps) => {
  function handleCardClick(): void {
    props.analyticsAction();

    pipe(
      props.navigation,
      E.fold(
        nav => nav.dispatch(),
        extLink => { window.open(extLink.dataHref, extLink.dataTarget); },
      )
    );

  }
  function handleCardPress(event: KeyboardEvent<HTMLElement>): void {
    if (event.key === Key.Enter) handleCardClick();
  }
  return <Card {...props} onClick={handleCardClick} onKeyPress={handleCardPress} />;
};

type CardBodyProps = {
  children: ReactNode;
  klass?: KlassProp;
};

export const CardBody = (props: CardBodyProps) => {
  return (
    <div {...klassPropO(c[".card"][".card-body"])(props.klass)}>
      {props.children}
    </div>
  );
};

export const CardFooter = (props: CardBodyProps & { klasses: KlassList }) => {
  return (
    <div {...klass(c[".card"][".card-footer"], ...props.klasses)}>
      {props.children}
    </div>
  );
};

export const CardSkeleton = () =>
  <Card klasses={O.none}>
    <CardBody>
      <Skeleton count={2} />
    </CardBody>
  </Card>;
